export const regions = [
  { label: "US", value: "US" },
  { label: "World", value: "world" },
  { label: "North America", value: "North America" },
  { label: "Africa", value: "Africa" },
  { label: "Asia", value: "Asia" },
  { label: "Europe", value: "Europe" },
  { label: "South America", value: "South America" },
  { label: "Oceania", value: "Oceania" },
];

export const NAV_LINKS = [
  {
    url: `${process.env.REACT_APP_BRAINTRUST_URL}solutions`,
    label: "For Companies",
    subLinks: [
      {
        url: `${process.env.REACT_APP_BRAINTRUST_URL}solutions`,
        label: "Solutions",
      },
      {
        url: `${process.env.REACT_APP_BRAINTRUST_URL}air`, 
        label: "NEW: Braintrust AIR",
      },
      {
        url: `${process.env.REACT_APP_BRAINTRUST_URL}why-braintrust`,
        label: "Why Braintrust",
      },
      {
        url: `${process.env.REACT_APP_BRAINTRUST_URL}skills`,
        label: "Skills & Capabilities",
      },

      {
        url: `${process.env.REACT_APP_BRAINTRUST_URL}clients`,
        label: "Clients",
      },
      {
        url: `${process.env.REACT_APP_BRAINTRUST_URL}resources`,
        label: "Resources",
      },
    ],
  },
  {
    url: `${process.env.REACT_APP_BRAINTRUST_URL}how-it-works-for-talent`,
    label: "For Talent",
    subLinks: [
      {
        url: `${process.env.REACT_APP_BRAINTRUST_APP_URL}jobs`,
        label: "Jobs",
      },
      {
        url: `${process.env.REACT_APP_BRAINTRUST_URL}how-it-works-for-talent`,
        label: "How it Works",
      },
      {
        url: `${process.env.REACT_APP_BRAINTRUST_URL}professional-network`,
        label: "Professional Network",
      },
      {
        url: `${process.env.REACT_APP_BRAINTRUST_URL}benefits`,
        label: "Benefits",
      },
      {
        url: `${process.env.REACT_APP_BRAINTRUST_URL}resources?tab=talent`,
        label: "Resources",
      },
      {
        url: `${process.env.REACT_APP_BRAINTRUST_URL}refer-clients`,
        label: "Refer & Earn",
      },
    ],
  },
  {
    url: `${process.env.REACT_APP_BRAINTRUST_URL}about`,
    label: "About Us",
    subLinks: [
      {
        url: `${process.env.REACT_APP_BRAINTRUST_URL}about`,
        label: "Mission",
      },
      {
        url: `${process.env.REACT_APP_BRAINTRUST_URL}press`,
        label: "Press",
      },
      {
        url: `${process.env.REACT_APP_BRAINTRUST_URL}community`,
        label: "Community",
      },
      {
        url: `${process.env.REACT_APP_BRAINTRUST_URL}btrst-token`,
        label: "BTRST",
      },
      {
        url: `https://info.app.usebraintrust.com/`,
        label: "Network Dashboard",
      },
    ],
  },
  {
    url: `${process.env.REACT_APP_BRAINTRUST_URL}blog`,
    label: "Blog",
  },
  {
    url: process.env.REACT_APP_BRAINTRUST_APP_URL,
    label: "Log in",
  },
];

export const SIGN_UP_LINK = `${process.env.REACT_APP_BRAINTRUST_APP_URL}auth/sign_up/goals`;

export const BOOK_DEMO_LINK = `${process.env.REACT_APP_BRAINTRUST_URL}contact-sales`;
