import React, { useState } from 'react';
import Button from '../Button';
import Label from '../Label';
import Link from '../Link';

import { NAV_LINKS, SIGN_UP_LINK, BOOK_DEMO_LINK } from '../../constants';
import { ArrowDownIcon, ArrowRightIcon, Logo, CloseBlackIcon, BlackLine } from '../../assets/svgs';

import './styles.scss';

export default function NavigationMobile({ scrolling }) {
  const [expanded, setExpanded] = useState(false);

  const onToggleMenu = () => {
    setExpanded(!expanded);
  };

  const goToSignUp = () => {
    window.location.href = SIGN_UP_LINK;
  };

  const goToBookDemo = () => {
    window.location.href = BOOK_DEMO_LINK;
  };

  return (
    <div className={`navigation-mobile ${scrolling ? 'navigation-mobile__scrolled' : ''}`}>
      <div className="navigation-mobile__header">
        <Link
          styles="navigation__logo"
          url={`${process.env.REACT_APP_BRAINTRUST_URL}?hsLang=en`}
        >
          <Logo className="navigation__logo__img" />
        </Link>
        <Button onClick={onToggleMenu}>
          {expanded ? (
            <CloseBlackIcon width={20} height={20} />
          ) : (
            <div className="navigation-mobile__header__icon">
              <BlackLine className="navigation-mobile__header__icon__1" width={20.69} height={1.25} />
              <BlackLine className="navigation-mobile__header__icon__2" width={20.69} height={1.25} />
            </div>
          )}
        </Button>
      </div>
      {expanded && (
        <div className="navigation-mobile__content">
          {NAV_LINKS.map((link) => {
            return <NavLink key={link.label} link={link} />;
          })}
          <div className="navigation-mobile__blackline" />
          <div className="navigation-mobile__content__item">
            <Button styles="navigation-mobile__content__item__btn content" onClick={goToBookDemo}>
              <Label type="primary" size="large" font="medium">
                Book Demo
              </Label>
              <ArrowRightIcon width={25} height={25} />
            </Button>
          </div>
          <div className="navigation-mobile__blackline" />
          <div className="navigation-mobile__content__item">
            <Button styles="navigation-mobile__content__item__btn content first-item" onClick={goToSignUp}>
              <Label type="primary" size="large" font="medium">
                Sign Up
              </Label>
              <ArrowRightIcon width={25} height={25} />
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

const NavLink = ({ link }) => {
  const [subMenuExpanded, setSubMenuExpanded] = useState(false);

  return (
    <div className="navigation-mobile__content__item">
      <div className="navigation-mobile__content__item__content">
        <Link url={link.url} styles="link--no-padding">
          <Label type="primary" size="large" font="regular" className="bold">
            {link.label}
          </Label>
        </Link>
        {!!link.subLinks && (
          <Button onClick={() => setSubMenuExpanded((prev) => !prev)}>
            <ArrowDownIcon width={15} height={10} />
          </Button>
        )}
      </div>
      {link.subLinks && subMenuExpanded && (
        <div className="navigation-mobile__content__item__submenu">
          {link.subLinks.map((subLink) => (
            <div key={subLink.label} className="navigation-mobile__content__item__submenu__item">
              <BlackLine width={20.69} height={1.25} />
              <Link url={subLink.url}>
                <Label type="primary" size="large">
                  {subLink.label}
                </Label>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
